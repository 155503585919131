@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:wght@400;600;700&family=Playfair+Display:wght@400;700&family=Water+Brush&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
a {
  text-decoration: none;
}
.btn-primary {
  background: #d69991;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 0;
  padding: 0.4rem 2rem;
  text-transform: uppercase;
  border: none;
}
.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
  color: #d69991;
  border-color: #d69991;
  font-size: 14px;
  padding: 0.4rem 2rem;
  border-radius: 0;
  outline: none;
}
.btn-outline-primary.active {
  background: #d69991;
  color: white;
  border-color: #d69991;
}
.btn-outline-primary:hover {
  background: #d69991;
  border-color: #d69991;
}
.btn-outline-primary:disabled {
  border-color: #d69991;
  color: #d69991;
}
.btn-primary:hover {
  background: #b1756d;
}
.text-primary {
  color: #d59991 !important;
}
.text-secondary {
  color: #4f46e5 !important;
}
.bg-primary {
  background: #efe9e8 !important;
}

.navbar {
  background: white;
  position: sticky;
  top: 0;
  z-index: 100;
}

.logo {
  font-family: "Water Brush", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 70px;
  letter-spacing: -1.1px;
  color: #222222;
  cursor: pointer;
}
.link {
  font-size: 14px;
  color: #d69991;
  font-weight: bold;
  margin: 0;
  position: relative;
  cursor: pointer;
}
.link::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #d69991;
  display: none;
}
.link:hover::after {
  display: block;
}
.hero_container {
  height: calc(100vh - 100px);
}
.img_box {
  width: 50%;
  height: 90%;
  position: relative;
}
.img_box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero_content {
  position: absolute;
  width: 40rem;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  box-shadow: 0px 40px 160px rgba(15, 15, 15, 0.16);
  padding: 3rem;
}
.small_heading {
  text-transform: uppercase;
  color: #d6a091;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
}
.main_heading {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -2.2px;
}
.small_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.7rem;
  letter-spacing: 0.7px;
  color: #888888;
}
.portfolio {
  background: #efe9e8;
  position: relative;
}
.portfolio_content {
  width: 40%;
}
.portfolio_images_container {
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translateY(-50%);
}
.portfolio_image_card {
  width: 20rem;
  height: 100%;
  position: relative;
}
.portfolio_image_card > img {
  width: 100%;
  height: 30rem;
  object-fit: cover;
}
.portfolio_card_wrapper {
  position: absolute;
  top: 65%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
.card_heading {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
}
.card_date {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 20px;
  text-align: center;
  position: relative;
}
.card_date::after {
  content: "";
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 1.5rem;
  height: 0.2rem;
  background: rgba(255, 255, 255, 0.5);
}
.plans_heading {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 2rem;
}
.plans_card {
  border: 1px solid #d59991;
  box-shadow: 0px 40px 80px rgba(15, 15, 15, 0.1);
  width: 13rem;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  transition: all 0.2s linear;
  margin-bottom: 1rem;
}

.plans_card * {
  text-align: center;
}
.plans_card_details {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
}
.plans_card > .card_heading {
  color: black;
}
.plans_card.active,
.plans_card:hover {
  background: #d59991;
}
.plans_card.active *,
.plans_card:hover * {
  color: white;
}

.process_container {
  background: #efe9e8;
}
.process_box {
  max-width: 50rem;
}
.process_card {
  width: 40%;
  margin: 0 5%;
  margin-bottom: 5rem;
}
.proccess_num {
  background: #d69991;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  color: white;
  margin: 0;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
.counting_box * {
  text-align: center;
  color: black;
}

.contact_container {
  background: #efe9e8;
}
.contact_icon {
  width: 5rem;
}
.contact-inner {
  max-width: 50%;
}

.footer {
  background: #222222;
}
.footer .logo {
  color: #666666;
}
.social_button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #d69991;
  display: grid;
  place-items: center;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.2s linear;
}
.social_button > img {
  width: 1rem;
}
.social_button:hover {
  background: #b1756d;
}
.screen {
  width: 100vw;
  min-height: 100vh;
  background: #efe9e8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.close_btn {
  position: absolute;
  right: 5rem;
  top: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border: 1px solid #d69991;
  outline: none;
  color: #d69991;
  transition: all 0.2s linear;
}
.close_btn i {
  font-size: 1.3rem;
}
.close_btn:hover {
  background: #d69991;
  color: white;
}
.white_box {
  background: white;
  width: 60%;
  padding: 4rem;
  margin-bottom: 2rem;
}
.short_heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.7px;
}
.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.screen .form-input {
  width: 25rem;
}
.screen .white_box {
  width: auto;
}
.form-input {
  padding: 0.8rem 1rem;
  border: 1px solid black;
  border-radius: 0;
  margin: 0.5rem 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  width: 100%;
}
.form_bottom_links {
  display: flex;
  justify-content: center;
}
.form_bottom_links .plans_card_details {
  cursor: pointer;
}
.form_bottom_links span {
  width: 1px;
  background: rgba(0, 0, 0, 0.3);
}
.blog_card {
  width: 32%;
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: 0px 10px 55px -3px rgba(0, 0, 0, 0.1),
    0px 4px 46px -2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin-bottom: 2rem;
}
.blog_card img {
  width: 100%;
  height: 11rem;
  object-fit: cover;
}
.blog_small_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}
.blog_card .short_heading {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
}
.small_text.blog_small_text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.4rem;
  font-size: 0.8rem;
}
.bloger_profile img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 50%;
}
.blog_img img {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  float: right;
  margin: 0 2rem;
}
.order_form {
  background: #efe9e8;
  min-height: calc(100vh - 7rem);
  display: grid;
  place-items: center;
}
.order_step {
  cursor: pointer;
}
.order_step > .proccess_num {
  background: none;
  border: 1px solid #d69991;
  color: #d69991;
}
.order_step > .small_heading {
  color: black;
}
.order_step.active > .proccess_num {
  background: #d69991;
  color: white;
}
textarea {
  resize: none;
  height: 8rem;
}
.select_div {
  width: 49%;
  cursor: pointer;
}
.select_div.active {
  color: #d69991;
  border-color: #d69991;
}
.radio_input {
  display: none;
}
.radio__radio {
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid black;
}
.radio__radio::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #d69991;
  display: block;
  transform: scale(0);
}
.radio_input:checked + .radio__radio::after {
  transform: scale(1);
}
.upload_file {
  border: 2px dashed #888888;
  width: 100%;
  height: 12rem;
  cursor: pointer;
}
.upload_file > .small_heading {
  color: #aaaaaa;
  font-size: 0.8rem;
}
.upload_file > img {
  width: 60px;
}
.video_short_heading > span {
  color: #d69991;
  text-decoration: underline;
  cursor: pointer;
}
.order_received_card {
  width: 35rem;
}
.order_received_card > img {
  width: 300px;
}
.order_received_card .plans_card_details {
  color: #888888;
}

.order_progress {
  padding-bottom: 1.2rem;
  position: relative;
}
.progress_icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  display: grid;
  place-items: center;
  z-index: 1;
}
.progress_icon::before {
  content: "";
  position: absolute;
  width: 1px;
  background: #d69991;
  height: 100%;
  z-index: -1;
  bottom: 0;
}
.order_progress:last-child .progress_icon::before {
  display: none;
}
.progress_icon.active {
  background: #d69991;
  border-color: #d69991;
}
.progress_icon i {
  font-size: 0.7rem;
  color: white;
  display: none;
}
.progress_icon.active i {
  display: block;
}
.plans_card_details.order_date {
  font-size: 0.6rem;
  color: #333333;
  font-weight: 500;
  margin: 0;
  margin-top: 0.2rem;
}
.white_box.order_box {
  width: 20rem;
  min-height: 25rem;
}
.white_box.order_box button {
  width: 100%;
  text-align: start;
}
.white_box.order_requirements {
  width: 100%;
}
.small_text.key {
  width: 20%;
  font-size: 0.9rem;
  font-weight: 500;
}
.small_heading.value {
  width: 80%;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
}
.small_heading.value span {
  color: #d69991;
  cursor: pointer;
}
.order_status {
  width: 25%;
  position: relative;
  z-index: 1;
}
.order_status > .proccess_num {
  background: #efe9e8;
}
.order_status > .proccess_num::after {
  content: "";
  position: absolute;
  width: 100%;
  border: 1px dashed #888888;
  left: 0;
  z-index: -1;
}
.order_status:last-child > .proccess_num::after {
  display: none;
}
.upload_container > * {
  width: 48%;
  height: 20rem;
}
.upload_container textarea {
  border: 1px solid #333333;
  border-radius: 0;
  outline: none;
  padding: 1.5rem;
  font-weight: 500;
  color: #888888;
  font-size: 1.2rem;
  line-height: 40px;
  letter-spacing: 0.7px;
  font-family: "Montserrat", sans-serif;
}
.comments_modal .close_btn {
  background: none;
  right: 1rem;
  top: 0.8rem;
}
.comments_modal .close_btn:hover {
  background: #d69991;
}
.message_box {
  background: #efe9e8;
}
.comment_time {
  color: black;
  font-weight: 500 !important;
}
.message {
  width: 80%;
}
.message_container {
  height: 25rem;
  overflow-y: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.white_box.admin_white_box {
  min-height: auto;
}
.editors_select {
  cursor: pointer;
  background: #fafafa;
}
table {
  border-collapse: collapse;
  border: 1px solid #b1b1b1;
}
th {
  border-bottom: 1px solid #b1b1b1;
}
td,
th {
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
/* @media (min-width: 576px) {
  .comments_modal .modal-dialog {
    max-width: 600px;
  }
} */

@media only screen and (max-width: 600px) {
  .navbar div {
    justify-content: center !important;
  }
  .navbar .logo {
    font-size: 38px;
  }
  .hero_container {
    height: 0;
  }
  .img_box {
    width: 0;
    height: 100%;
  }
  .img_box img {
    display: none;
  }
  .top-hero-container {
    padding-left: 0;
    margin-bottom: 25%;
    background-color: red;
    height: 500px;
    margin-top: 50px;
    /* height: 80vh; */
  }
  .second-hero-container {
    height: 500px;
  }

  .top-hero-container .hero_content {
    width: 100vw !important;
  }
  .top-hero-container h2 {
    font-size: 2.7rem;
    line-height: 3.5rem;
  }

  .main_heading {
    font-size: 3rem;
  }
  .portfolio_content {
    /* margin-top: 80%; */
    width: 100%;
  }
  .portfolio_images_container {
    display: none !important;
  }
  .plans_heading {
    text-align: center;
  }
  .plans_card {
    width: 20rem;
  }
  .plans_card_details {
    font-size: 14px;
  }

  .plans_container div {
    justify-content: space-around !important;
  }
  .process_container {
    padding: 30px 0px !important;
  }
  .process_container h2 {
    font-size: 40px;
  }
  .process_container .process_box {
    width: 100vw;
    flex-direction: column !important;
    align-items: flex-start;
  }
  .process_card {
    width: 70%;
  }
  .proccess_num {
    width: 1rem;
    height: 3rem;
  }

  .process_card div {
    justify-content: space-between !important;
  }
  .counting-container {
    flex-direction: column;
    gap: 3rem;
  }
  .contact-inner {
    max-width: 85%;
  }
  .footer .container {
    flex-direction: column;
  }
  .footer .container h3 {
    text-align: center;
    font-size: 40px;
  }
  .blog-container {
    flex-direction: column;
    align-items: center;
  }
  .blog_card {
    width: 85%;
  }
}
